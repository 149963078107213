<template>
	<v-sheet class="create-Lead create-page" id="lead">
		<div class="px-3">
			<v-row class="sticky">
				<v-col md="6" class="my-auto">
					<h1 class="custom-header-blue-text m-0">{{ pageTitle }}</h1>
				</v-col>
				<v-col md="6" class="text-right">
					<v-btn
						:loading="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						:disabled="pageLoading || !formValid"
						@click="update_or_create"
					>
						Save
					</v-btn>

					<template v-if="leadsId > 0">
						<v-btn :disabled="pageLoading" depressed tile @click="$emit('close', true)"> Cancel </v-btn>
					</template>
					<template v-else>
						<v-btn :disabled="pageLoading" depressed tile @click="goBack"> Cancel </v-btn>
					</template>
				</v-col>
			</v-row>
			<v-form
				ref="form"
				id="leadForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="update_or_create"
			>
				<v-row class="">
					<v-col cols="12">
						<div class="form-grp-title">Lead Information</div>
						<v-row class="px-4">
							<v-col cols="12" md="10" lg="6" class="pr-lg-15">
								<v-row>
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Status</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 position-relative">
										<v-autocomplete
											hide-details
											:items="statusList"
											placeholder="Status"
											outlined
											v-model="create_lead.status"
											item-text="text"
											item-value="value"
											:rules="[vrules.required(create_lead.status, 'Status')]"
											:class="{
												required: !create_lead.status,
											}"
										>
										</v-autocomplete>
										<!-- <ManageAutocomplete title="Status" typeId="1" v-on:update="getSettings"></ManageAutocomplete> -->
									</v-col>
								</v-row>
							</v-col>
							<v-col md="10" lg="6" class="pl-lg-15">
								<v-row>
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Source</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 position-relative">
										<v-autocomplete
											hide-details
											:items="sourceList"
											placeholder="Source"
											outlined
											v-model="create_lead.source"
											item-text="text"
											item-value="value"
											class=""
											:rules="[vrules.required(create_lead.source, 'Source')]"
											:class="{
												required: !create_lead.source,
											}"
										>
										</v-autocomplete>
										<!-- <ManageAutocomplete title="Source" typeId="2" v-on:update="getSettings">
										</ManageAutocomplete> -->
									</v-col>
								</v-row>
							</v-col>
							<v-col md="10" lg="6" class="pr-lg-15">
								<v-row>
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Opportunity</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
										<!-- <v-autocomplete
											hide-details
											:items="opportunityList"
											placeholder="Opportunity"
											outlined
											v-model="create_lead.opportunity"
											item-text="text"
											item-value="value"
											class=""
										>
										</v-autocomplete>
										<ManageAutocomplete
											title="Opportunity"
											typeId="3"
											v-on:update="getSettings"
										></ManageAutocomplete> -->
										<v-radio-group row hide-details class="mt-0" v-model="create_lead.opportunity">
											<v-radio
												style="min-width: 117px !important"
												color="orange"
												label="High Chance"
												value="high-chance"
											></v-radio>
											<v-radio
												style="min-width: 117px !important"
												color="green"
												label="50 50"
												value="50-50"
											></v-radio>
											<v-radio
												style="min-width: 117px !important"
												color="cyan"
												label="Low Chance"
												value="low-chance"
											></v-radio>
											<v-radio
												style="min-width: 117px !important"
												color="blue"
												label="None"
												value="none"
											></v-radio>
										</v-radio-group>
									</v-col>
								</v-row>
							</v-col>
							<v-col md="10" lg="6" class="pl-lg-15">
								<v-row>
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Product type</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
										<v-autocomplete
											hide-details
											:items="productList"
											placeholder="Product Type"
											outlined
											v-model="create_lead.product_type"
											item-text="text"
											item-value="value"
											class=""
											:rules="[vrules.required(create_lead.product_type, 'Product Type')]"
											:class="{
												required: !create_lead.product_type,
											}"
										>
										</v-autocomplete>
										<ManageAutocomplete title="Product Type" typeId="4" v-on:update="getSettings">
										</ManageAutocomplete>
									</v-col>
								</v-row>
							</v-col>
							<v-col md="10" lg="6" class="pr-lg-15">
								<v-row>
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Assign To </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 position-relative">
										<v-autocomplete
											:items="userList"
											v-model="create_lead.assign"
											item-text="first_name"
											item-value="id"
											depressed
											:readonly="currentUser.role != 1 && currentUser.role != 4"
											placeholder="Assigned To"
											hide-details
											outlined
											hide-selected
											class="cuatom-drop"
											color="blue darken-4"
											small-chips
											deletable-chips
											:rules="[vrules.required(create_lead.assign, 'PAssigned To')]"
											:class="{
												required: !create_lead.assign,
											}"
										>
											<template #selection="data">
												<v-chip small v-bind="data.attrs">
													<v-avatar left>
														<img v-if="data.item.profile_img" :src="data.item.profile_img" />
														<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
													</v-avatar>

													<span class="text-uppercase fw-500">{{ data.item.display_name }}</span>
												</v-chip>
											</template>
											<template #item="data">
												<div class="py-1 d-flex align-center justify-space-between w-100">
													<div class="d-flex align-center">
														<v-avatar size="38">
															<img v-if="data.item.profile_img" :src="data.item.profile_img" />
															<img
																v-else
																src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
															/>
														</v-avatar>
														<div class="ms-2">
															<div class="fw-600 text-uppercase">{{ data.item.display_name }}</div>
															<div class="fw-600 text-muted">{{ data.item.email }}</div>
														</div>
													</div>

													<!-- <v-chip x-small label dark class="px-1" color="red">
														<span class="fw-500 fs-11 text-uppercase">{{ data.item.users_type }}</span>
													</v-chip> -->
												</div>
											</template>
										</v-autocomplete>
										<!-- 	<v-autocomplete
											hide-details
											:items="userList"
											placeholder="Assign To"
											outlined
											v-model="create_lead.assign"
											item-text="first_name"
											item-value="id"
											class=""
										>
										</v-autocomplete> -->
										<!-- <ManageAutocomplete title="Assign" v-on:update="getSettings"></ManageAutocomplete> -->
									</v-col>
								</v-row>
							</v-col>
							<v-col md="10" lg="6" class="pl-lg-15">
								<v-row>
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Amount</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											type="number"
											placeholder="Amount"
											v-model="create_lead.amount"
											:disabled="pageLoading"
											:loading="pageLoading"
											v-on:keypress="limitDecimal($event, create_lead.amount)"
											v-on:paste="limitDecimal($event, create_lead.amount)"
										>
										</v-text-field>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-divider></v-divider>
						<v-row class="px-4">
							<v-col cols="12" md="10" lg="6" class="pr-lg-15">
								<!-- <v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Person Incharge</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											placeholder="Person Incharge"
											v-model="create_lead.customer_name"
											:disabled="pageLoading"
											:loading="pageLoading"
											:rules="[vrules.required(create_lead.customer_name, 'Person Incharge')]"
											:class="{
												required: !create_lead.customer_name,
											}"
											v-on:keyup="appendContactdetail()"
										>
										</v-text-field>
									</v-col>
								</v-row> -->

								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label required">Company Name</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											placeholder="Company Name"
											v-model="create_lead.company_name"
											:disabled="pageLoading"
											:loading="pageLoading"
											:rules="[vrules.required(create_lead.company_name, 'Company Name')]"
											:class="{
												required: !create_lead.company_name,
											}"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Position</label>
									</v-col>

									<!-- <v-text-field hide-details outlined placeholder="Position" v-model="create_lead.position">
										</v-text-field> -->
									<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
										<v-autocomplete
											hide-details
											:items="positonList"
											placeholder="Position"
											outlined
											v-model="create_lead.position"
											item-text="text"
											item-value="value"
											class=""
										>
										</v-autocomplete>
										<ManageAutocomplete title="Position" typeId="54" v-on:update="getSettings">
										</ManageAutocomplete>
									</v-col>
								</v-row>

								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Email(Main)</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											placeholder="Email"
											v-model="create_lead.email"
											:rules="[vrules.validEmail(create_lead.email, 'Email Address')]"
											:disabled="pageLoading"
											:loading="pageLoading"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Phone(Main) / Company number</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<PhoneTemplate
											:disabled="pageLoading"
											:loading="pageLoading"
											@loading="($event) => (pageLoading = $event)"
											id="phone_number"
											v-model="create_lead.phone"
											@validity="
												($event) => {
													if (!$event) {
														create_lead.phone = null;
													}
												}
											"
										></PhoneTemplate>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Website</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field hide-details outlined placeholder="Website" v-model="create_lead.website">
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Attachments </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<template v-for="(file, index) in files">
											<v-row :key="index">
												<v-col lg="5" cols="5" class="my-auto py-0 d-flex align-items-center overflow-hidden">
													<v-file-input
														:id="`document-file-${index}`"
														placeholder="Select File"
														outlined
														class="mt-3"
														style="white-space: nowrap; overflow-x: hidden"
														prepend-icon=""
														prepend-inner-icon="mdi-attachment"
														hide-details
														v-model="file.file"
														@change="updateFile(index, $event)"
														@click:clear="updateFile(index, $event)"
													></v-file-input>
												</v-col>
												<v-col lg="5" cols="5" class="my-auto py-0 d-flex align-items-center">
													<TextInput
														:id="`document-name-${index}`"
														v-model="file.name"
														hide-details
														placeholder="File Name"
														:suffix="file.suffix"
													>
													</TextInput>
												</v-col>
												<v-col lg="1" cols="1" class="px-0 my-auto py-0 d-flex justify-content-centre">
													<v-btn
														style="padding: 0px !important; min-width: 10px !important"
														:disabled="files.length < 2"
														v-on:click="removeFile(index)"
														color="red lighten-1 white--text"
														class="mt-3"
														icon
													>
														<v-icon>mdi-delete</v-icon>
													</v-btn>
												</v-col>
												<v-col lg="1" cols="1" class="my-auto py-0 d-flex justify-content-centre">
													<v-btn
														v-if="files.length < 5 && index == files.length - 1"
														color="blue darken-4 white--text"
														class="mt-3"
														icon
														style="padding: 0px !important; min-width: 10px !important"
														v-on:click="addMore()"
													>
														<v-icon>mdi-plus</v-icon>
													</v-btn>
												</v-col>
											</v-row>
										</template>
									</v-col>
								</v-row>
							</v-col>
							<v-col cols="12" md="10" lg="6" class="pl-lg-15">
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Lead Date</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<!-- <v-menu
											v-model="date_menu"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											max-width="290px"
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="create_lead.lead_date"
													append-icon="mdi-calendar"
													placeholder="dd/mm/yyyy"
													outlined
													readonly
													hide-details
													v-bind="attrs"
													v-on="on"
												></v-text-field>
											</template>
											<v-date-picker
												:min="today"
												v-model="create_lead.lead_date"
												no-title
												@input="date_menu = false"
											></v-date-picker>
										</v-menu> -->
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="Lead-date"
											placeholder="Lead Date"
											:min-date="minDate"
											:max-date="maxDate"
											v-model="create_lead.lead_date"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Postal Code </label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											type="number"
											placeholder="Postal code"
											v-model.trim="create_lead.postal_code"
											:rules="[vrules.maxLength(create_lead.postal_code, 'Postal Code', 10)]"
											v-mask="'######'"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Address Line 1</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											placeholder="Address Line 1"
											v-model="create_lead.address_line_1"
											v-on:keypress="(e) => manageLimit(e, 'address_line_1')"
											v-on:paste="(e) => onPaste(e, 'address_line_1')"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Address Line 2</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											placeholder="Address Line 2"
											v-model="create_lead.address_line_2"
											v-on:keypress="(e) => manageLimit(e, 'address_line_2')"
											v-on:paste="(e) => onPaste(e, 'address_line_2')"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Unit Number</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											placeholder="Unit Number"
											v-model="create_lead.unit_number"
											v-on:keypress="(e) => manageLimit(e, 'unit_number')"
											v-on:paste="(e) => onPaste(e, 'unit_number')"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Country</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 position-relative">
										<v-autocomplete
											:items="countryList"
											hide-details
											outlined
											auto-select-first
											v-model="create_lead.country"
											placeholder="Country"
										>
										</v-autocomplete>

										<!-- <ManageAutocomplete title="Country" typeId="5" v-on:update="getSettings">
										</ManageAutocomplete> -->
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12">
						<div class="form-grp-title d-flex">
							<div>Contact Person</div>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-4"
								class="white--text my-auto"
								depressed
								tile
								@click="add_person()"
							>
								<v-icon left>mdi-plus</v-icon>
								Add
							</v-btn>
						</div>

						<!-- <v-row class="mb-3">
							<v-col style="max-width: 60px">
								<label class="fw-500">Primary</label>
							</v-col>
							<v-col>
								<label class="fw-500 required ms-5">Contact Name</label>
							</v-col>
							<v-col>
								<label class="fw-500 ms-2">Email Address</label>
							</v-col>
							<v-col>
								<label class="fw-500">Designation</label>
							</v-col>
						</v-row> -->

						<v-row>
							<template v-for="(data, cindex) in create_lead.contact_person">
								<v-col md="11" :key="cindex + '_' + 'contact_person_1'" cols="12">
									<v-row class="pl-4">
										<v-col style="max-width: 60px">
											<label class="fw-500 mb-3">Primary</label>
											<v-checkbox
												v-model="create_lead.contact_person[cindex].primary"
												hide-details
												style="margin-top: 0px !important"
												:readonly="create_lead.contact_person[cindex].primary == true ? true : false"
												v-on:change="checkBoxtype(cindex)"
											></v-checkbox>
										</v-col>

										<v-col>
											<label class="fw-500 required mb-2">Contact Name</label>
											<v-text-field
												type="text"
												placeholder="Contact Name"
												v-model="create_lead.contact_person[cindex].contact_name"
												:rules="[
													vrules.required(create_lead.contact_person[cindex].contact_name, 'Contact Name'),
												]"
												:class="{
													required: !create_lead.contact_person[cindex].contact_name,
												}"
												outlined
												hide-details
												color
											>
											</v-text-field>
										</v-col>

										<v-col>
											<label class="fw-500 mb-2">Email Address</label>
											<v-text-field
												type="email"
												placeholder="Email Address"
												@blur="validateEmail(cindex)"
												v-model="create_lead.contact_person[cindex].email_address"
												:rules="[
													vrules.validEmail(create_lead.contact_person[cindex].email_address, 'Email Address'),
												]"
												outlined
												hide-details
												color
											>
											</v-text-field>
										</v-col>

										<v-col class="my-auto py-0 position-relative">
											<label class="fw-500 mb-2">Designation</label>
											<v-autocomplete
												:items="designationList"
												item-text="text"
												item-value="value"
												hide-details
												class="pr-10"
												outlined
												placeholder="Designation"
												v-model="create_lead.contact_person[cindex].designation"
											>
											</v-autocomplete>
											<ManageAutocomplete
												title="Designation"
												customClass="mt-7"
												typeId="25"
												v-on:update="getSettings"
											>
											</ManageAutocomplete>
										</v-col>
									</v-row>

									<v-row class="pl-4">
										<v-col style="max-width: 60px"> </v-col>

										<v-col class="mt-3">
											<label class="fw-500 mb-2">Phone No/Mobile No</label>
											<PhoneTemplate
												:disabled="pageLoading"
												id="phone_number_Contact"
												class-name="mt-0"
												v-model="create_lead.contact_person[cindex].phone_number"
												:loading="pageLoading"
												@loading="($event) => (pageLoading = $event)"
												@blur="validatePhone(cindex)"
												@validity="phoneValid($event, cindex, 'phone_number')"
												@clear="create_lead.contact_person[cindex].phone_number = null"
											></PhoneTemplate>
										</v-col>

										<v-col class="mt-3">
											<label class="fw-500 mb-2">DID</label>
											<PhoneTemplate
												:disabled="pageLoading"
												:loading="pageLoading"
												id="did"
												:placeholder="'DID'"
												class-name="mt-0"
												v-model="create_lead.contact_person[cindex].did_number"
												@loading="($event) => (pageLoading = $event)"
												@validity="phoneValid($event, cindex, 'did_numbers')"
												@clear="create_lead.contact_person[cindex].did_number = null"
											>
											</PhoneTemplate>
										</v-col>

										<v-col class="mt-3 pr-3">
											<label class="fw-500 mb-2">Landline Number </label>
											<PhoneTemplate
												:disabled="pageLoading"
												:loading="pageLoading"
												id="did"
												:placeholder="'Landline Number'"
												class-name="mt-0"
												v-model="create_lead.contact_person[cindex].land_line_number"
												@loading="($event) => (pageLoading = $event)"
												@validity="phoneValid($event, cindex, 'land_line_number')"
												@clear="create_lead.contact_person[cindex].land_line_number = null"
											>
											</PhoneTemplate>
										</v-col>
									</v-row>
								</v-col>

								<v-col class="d-flex align-center justify-center" md="1" :key="cindex + '_' + 'delete-btn'">
									<template
										v-if="leadId > 0 && cindex > 0 && create_lead.contact_person[cindex].primary == 0"
									>
										<v-icon color="red" @click="delete_contact(create_lead.contact_person[cindex].id, cindex)"
											>mdi-delete</v-icon
										>
									</template>
									<template v-else>
										<v-icon
											v-if="create_lead.contact_person[cindex].primary == 0"
											color="red"
											@click="delete_person(cindex)"
											>mdi-delete</v-icon
										>
									</template>
								</v-col>
								<v-col
									md="12"
									:key="cindex + '_' + 'divider'"
									v-if="cindex < create_lead.contact_person.length - 1"
								>
									<v-divider></v-divider>
								</v-col>
							</template>
						</v-row>
					</v-col>
					<v-col cols="12">
						<div class="form-grp-title">Extra Information</div>
						<v-row class="px-4 position-relative">
							<v-col cols="12" md="6">
								<label>Internal remark</label>
								<TextAreaInput
									hide-details
									placeholder="Internal remark"
									v-model="create_lead.remark"
									:word-limit="500"
									:rows="5"
								></TextAreaInput>
								<!-- <v-textarea v-model="create_lead.remark" outlined hide-details row="2"> </v-textarea> -->
							</v-col>
							<v-col cols="12" md="6">
								<label>Email Reply(Inquiry Message)</label>
								<TextAreaInput
									hide-details
									placeholder="Internal remark"
									v-model="create_lead.email_reply"
									:word-limit="500"
									:rows="5"
								></TextAreaInput>
								<!-- 	<v-textarea v-model="create_lead.email_reply" outlined hide-details> </v-textarea> -->
							</v-col>
							<v-col cols="12" md="6">
								<div class="d-flex">
									<v-checkbox
										v-model="create_lead.is_public"
										label="Public"
										hide-details
										v-on:change="changedata(create_lead.is_public, 'public')"
									></v-checkbox>
									<v-checkbox
										v-model="create_lead.contacted_today"
										v-on:change="changedata(create_lead.contacted_today, 'contacted')"
										label="Contacted Today"
										hide-details
										class="ml-5"
									></v-checkbox>
								</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-form>
		</div>
	</v-sheet>
</template>
<style scoped>
.v-dialog__content.v-dialog__content--active {
	z-index: 202 !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import { toSafeInteger, map, forEach, toString, cloneDeep, find } from "lodash";
import { getCurrentUser } from "@/core/services/jwt.service";
import { LeadEventBus } from "@/core/lib/leadremark.lib";
import objectPath from "object-path";

/* import { RemarkEventBus } from "@/core/lib/remark.lib"; */
import MomentJS from "moment-timezone";

export default {
	mixins: [MainMixin],
	name: "Create-Lead",
	props: {
		leadsId: {
			type: Number,
			default: 0,
		},
		isDialog: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		leadsId: {
			handler(param) {
				if (param > 0) {
					this.getLead();
				}
			},
			isDialog(isDialog) {
				if (isDialog) {
					this.getLead();
				}
			},
			/* 	if (param) {
			this.getLead();
			this.pageTitle = "Update Lead"; */
		},
	},
	data() {
		return {
			pageTitle: "Create new Leads",
			formValid: true,
			today: new Date(),
			currentPage: 0,
			date_menu: false,
			pageLoading: false,
			minDate: MomentJS().subtract(7, "days").format("YYYY-MM-DD"),
			maxDate: MomentJS().format("YYYY-MM-DD"),
			remark: null,
			leadId: null,
			email_reply: null,
			checkbox1: false,
			checkbox2: false,
			lead: {
				date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
				address1: null,
			},
			contact_person: [
				{
					name: null,
					email: null,
					phone: null,
				},
			],
			statusList: [],
			positonList: [],
			designationList: [],
			sourceList: [],
			opportunityList: [],
			productList: [],
			userList: [],
			countryList: [],
			files: [
				{
					file: null,
					name: null,
				},
			],
			phoneValidity: [],
			create_lead: {
				status: "not-contacted",
				source: "web-enquiry",
				opportunity: null,
				product_type: "seo",
				assign: 0,
				amount: 0,
				customer_name: null,
				company_name: null,
				position: null,
				email: null,
				phone: null,
				unit_number: null,
				website: null,
				lead_date: new Date().toISOString().substr(0, 10),
				address_line_1: null,
				address_line_2: null,
				country: "singapore",
				postal_code: null,
				remark: null,
				email_reply: null,
				is_public: 0,
				contacted_today: 1,
				user: 1,

				contact_person: [
					{
						id: 0,
						contact_name: null,
						email_address: null,
						phone_number: null,
						primary: true,
						designation: null,
						did_number: null,
						land_line_number: null,
					},
				],
			},
		};
	},

	components: {
		ManageAutocomplete: () => import("@/view/pages/leads/components/ManageAutocomplete"),
		PhoneTemplate: () => import("@/view/components/Phone"),
		DatePicker: () => import("@/view/components/DatePicker"),
		TextAreaInput: () => import("@/view/components/TextAreaInput"),
		TextInput: () => import("@/view/components/TextInput"),
	},

	methods: {
		phoneValid(validity, index, type) {
			if (!this.phoneValidity[index]) {
				this.phoneValidity[index] = {};
			}

			this.phoneValidity[index][type] = validity;
			/* this.formValid = validity;
			if (!validity) {
				this.create_lead.contact_person[index][type] = null;
			} */
		},
		limitDecimal($event, value) {
			let string = toString(value);

			// console.log($event.keyCode); //keyCodes string
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			// only allow number and one dot
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		onPaste(e, type) {
			let coppied = e.clipboardData.getData("Text");
			if (e && coppied && coppied.length > 24 && type == "address_line_1") {
				this.create_lead.address_line_1 = coppied.substring(0, 25);
				e.preventDefault();
			} else if (e && coppied && coppied.length > 24 && type == "address_line_2") {
				this.create_lead.address_line_2 = coppied.substring(0, 25);
				e.preventDefault();
			} else if (e && coppied && coppied.length > 24 && type == "unit_number") {
				this.create_lead.unit_number = coppied.substring(0, 25);
				e.preventDefault();
			}
		},
		manageLimit(e, type) {
			if (
				this.create_lead.address_line_1 &&
				this.create_lead.address_line_1.length > 24 &&
				type == "address_line_1"
			) {
				e.preventDefault();
			} else if (
				this.create_lead.address_line_2 &&
				this.create_lead.address_line_2.length > 24 &&
				type == "address_line_2"
			) {
				e.preventDefault();
			} else if (
				this.create_lead.unit_number &&
				this.create_lead.unit_number.length > 24 &&
				type == "unit_number"
			) {
				e.preventDefault();
			}
		},
		changedata(data, type) {
			if (type == "public") {
				if (data == true) {
					this.create_lead.is_public = 1;
				} else {
					this.create_lead.is_public = 0;
				}
			}
			if (type == "contacted") {
				if (data == true) {
					this.create_lead.contacted_today = 1;
				} else {
					this.create_lead.contacted_today = 0;
				}
			}
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		currentUserData() {
			const user = getCurrentUser();
			return user;
		},
		addMore() {
			if (this.files.length < 5) {
				this.files.push({
					file: null,
					name: null,
				});
			}
		},
		checkBoxtype(index) {
			let falseData = map(this.create_lead.contact_person, (row, rowIndex) => {
				if (rowIndex != index) {
					row.primary = false;
				}
				return row;
			});
			this.create_lead.contact_person = falseData;
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		validateEmail(index) {
			let primary_email = this.create_lead.contact_person[index].email_address;
			/* let contactId = this.create_lead.contact_person[index].id; */
			var emailsObj = this.create_lead.contact_person.filter(function (contactpersn) {
				if (contactpersn.email_address == primary_email) {
					return contactpersn.email_address;
				}
			});

			if (emailsObj.length > 1) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: `Contact email ${primary_email} already exists` },
				]);
				this.formValid = false;
				return false;
			}

			this.formValid = true;
		},
		validatePhone(index) {
			let primary_phone = this.create_lead.contact_person[index].phone_number;
			var phoneObj = this.create_lead.contact_person.filter(function (contactpersn) {
				if (contactpersn.phone_number == primary_phone) {
					return contactpersn.phone_number;
				}
			});

			if (phoneObj.length > 1) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: `Contact phone number ${primary_phone} already exists` },
				]);
				this.formValid = false;
				return false;
			}

			this.formValid = true;
		},
		/* appendContactdetail() {
			this.create_lead.contact_person[0].contact_name = this.create_lead.customer_name;
			this.create_lead.contact_person[0].email_address = this.create_lead.email;
			this.create_lead.contact_person[0].phone_number = this.create_lead.phone;
		}, */
		add_person() {
			this.create_lead.contact_person.push({
				contact_name: null,
				email_address: null,
				phone_number: null,
				designation: null,
				did_number: null,
				land_line_number: null,
				primary: false,
			});
		},
		delete_person(index) {
			this.create_lead.contact_person.splice(index, 1);
		},
		delete_contact(id, index) {
			if (this.create_lead.contact_person.length == 1) {
				this.create_lead.contact_person[index].primary == 1;
			}
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `lead-contact/${this.leadId}/${id}`,
					})
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Contact deleted successfully.",
							},
						]);
						this.getLead();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.create_lead.contact_person.splice(index, 1);
			}
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}
			if (this.create_lead.contact_person.length == 0) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Please add atleast one contact person." },
				]);
				return false;
			}
			forEach(this.create_lead.contact_person, (row) => {
				var emailsObj = this.create_lead.contact_person.filter(function (contactpersn) {
					if (contactpersn.email_address == row.email_address) {
						return contactpersn.email_address;
					}
				});
				var phonrObj = this.create_lead.contact_person.filter(function (contactpersn) {
					if (contactpersn.phone_number == row.phone_number) {
						return contactpersn.phone_number;
					}
				});
				if (emailsObj.length > 1) {
					this.$store.commit(SET_ERROR, [
						{ model: true, message: `Contact email ${row.email_address} already exists` },
					]);
					this.formValid = false;
					return false;
				}
				if (phonrObj.length > 1) {
					this.$store.commit(SET_ERROR, [
						{ model: true, message: `Contact phone number ${row.phone_number} already exists` },
					]);
					this.formValid = false;
					return false;
				}
			});

			if (this.phoneValidity.length) {
				let ErrArr = this.phoneValidity.filter(Boolean);
				let exem_validity = find(ErrArr, (row) => {
					if (row) {
						return row.phone_number == false || row.did_numbers == false || row.land_line_number == false;
					}
				});
				if (
					exem_validity &&
					(exem_validity.phone_number == false ||
						exem_validity.did_numbers == false ||
						exem_validity.land_line_number == false)
				) {
					// this.$store.commit(SET_ERROR, [
					// 		{ model: true, message: `Contact phone number ${row.phone_number} already exists` },
					// 	]);
					this.formValid = false;
					return false;
				}
			}

			if (!this.formValid) {
				return false;
			}

			this.pageLoading = true;
			let formData = new FormData();

			formData.append("status", this.create_lead.status ? this.create_lead.status : "proposal");
			formData.append("source", this.create_lead.source ? this.create_lead.source : "web-enquiry");
			if (this.create_lead && this.create_lead.opportunity) {
				formData.append(
					"opportunity",
					this.create_lead.opportunity ? this.create_lead.opportunity : null
				);
			}
			formData.append(
				"product_type",
				this.create_lead.product_type ? this.create_lead.product_type : "crm"
			);
			formData.append("assign", this.create_lead.assign ? this.create_lead.assign : 1);
			formData.append("amount", this.create_lead.amount ? this.create_lead.amount : 0);
			formData.append(
				"customer_name",
				this.create_lead.customer_name ? this.create_lead.customer_name : null
			);
			formData.append(
				"company_name",
				this.create_lead.company_name ? this.create_lead.company_name : null
			);
			if (objectPath.get(this.create_lead, "position")) {
				formData.append("position", this.create_lead.position ? this.create_lead.position : null);
			}
			if (objectPath.get(this.create_lead, "email")) {
				formData.append("email", this.create_lead.email ? this.create_lead.email : null);
			}
			if (objectPath.get(this.create_lead, "phone")) {
				formData.append("phone", this.create_lead.phone ? this.create_lead.phone : null);
			}

			if (objectPath.get(this.create_lead, "website")) {
				formData.append("website", this.create_lead.website ? this.create_lead.website : null);
			}
			if (objectPath.get(this.create_lead, "lead_date")) {
				formData.append("lead_date", this.create_lead.lead_date ? this.create_lead.lead_date : null);
			}
			if (objectPath.get(this.create_lead, "address_line_1")) {
				formData.append(
					"address_line_1",
					this.create_lead.address_line_1 ? this.create_lead.address_line_1 : null
				);
			}
			if (objectPath.get(this.create_lead, "address_line_2")) {
				formData.append(
					"address_line_2",
					this.create_lead.address_line_2 ? this.create_lead.address_line_2 : null
				);
			}
			if (objectPath.get(this.create_lead, "country")) {
				formData.append("country", this.create_lead.country ? this.create_lead.country : null);
			}

			formData.append("duplicate", this.leadDuplicate > 0 ? this.leadDuplicate : 0);

			if (objectPath.get(this.create_lead, "postal_code")) {
				formData.append(
					"postal_code",
					this.create_lead.postal_code ? this.create_lead.postal_code : null
				);
			}
			if (objectPath.get(this.create_lead, "unit_number")) {
				formData.append(
					"unit_number",
					this.create_lead.unit_number ? this.create_lead.unit_number : null
				);
			}
			if (objectPath.get(this.create_lead, "remark")) {
				formData.append(
					"remark",
					this.create_lead.remark ? this.create_lead.remark.replaceAll("\r", "") : null
				);
			}
			if (objectPath.get(this.create_lead, "email_reply")) {
				formData.append(
					"email_reply",
					this.create_lead.email_reply ? this.create_lead.email_reply.replaceAll("\r", "") : null
				);
			}
			if (objectPath.get(this.create_lead, "is_public")) {
				formData.append("is_public", this.create_lead.is_public ? this.create_lead.is_public : 0);
			}
			if (objectPath.get(this.create_lead, "contacted_today")) {
				formData.append(
					"contacted_today",
					this.create_lead.contacted_today ? this.create_lead.contacted_today : 0
				);
			}
			formData.append("user", this.create_lead.user ? this.create_lead.user : null);

			for (let i = 0; i < this.create_lead.contact_person.length; i++) {
				formData.append(
					`contact_person[${i}][id]`,
					this.create_lead.contact_person[i].id ? this.create_lead.contact_person[i].id : 0
				);
				formData.append(
					`contact_person[${i}][contact_name]`,
					this.create_lead.contact_person[i].contact_name
				);
				if (objectPath.get(this.create_lead, `contact_person.${i}.email_address`)) {
					formData.append(
						`contact_person[${i}][email_address]`,
						this.create_lead.contact_person[i].email_address
					);
				}
				if (objectPath.get(this.create_lead, `contact_person.${i}.phone_number`)) {
					formData.append(
						`contact_person[${i}][phone_number]`,
						this.create_lead.contact_person[i].phone_number
					);
				}
				if (objectPath.get(this.create_lead, `contact_person.${i}.did_number`)) {
					formData.append(
						`contact_person[${i}][did_number]`,
						this.create_lead.contact_person[i].did_number
					);
				}
				if (objectPath.get(this.create_lead, `contact_person.${i}.land_line_number`)) {
					formData.append(
						`contact_person[${i}][land_line_number]`,
						this.create_lead.contact_person[i].land_line_number
					);
				}
				if (objectPath.get(this.create_lead, `contact_person.${i}.designation`)) {
					formData.append(
						`contact_person[${i}][designation]`,
						this.create_lead.contact_person[i].designation
					);
				}

				if (objectPath.get(this.create_lead, `contact_person.${i}.primary`)) {
					formData.append(`contact_person[${i}][primary]`, this.create_lead.contact_person[i].primary);
				}
			}

			if (this.files && this.files.length > 0) {
				for (let i = 0; i < this.files.length; i++) {
					if (this.files && this.files[i] && this.files[i].file) {
						formData.append(`file[${i}][file]`, this.files[i].file);
					}
					if (this.files && this.files[i] && this.files[i].name) {
						formData.append(`file[${i}][name]`, this.files[i].name);
					}
				}
			}

			let requestTYPE = POST;
			let requestURL = "lead";

			if (this.leadId) {
				requestURL = `lead/${this.leadId}`;
			}
			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					/* let queryData = { t: new Date().getTime(), page :this.currentPage };
					console.log(queryData)
					this.$router.push({
					name: 'leads',
					query: queryData,
					}); */
					if (this.isDialog) {
						this.$emit("refresh", true);
						LeadEventBus.$emit("table-lead-refresh", true);
					} /* else{
						this.$router.push({
						name: 'leads-detail',
						params: data.id ,
						query: { t: new Date().getTime() },
						});
					  } */

					if (this.leadId > 0 && !this.isDialog) {
						let filterQuery = this.$route.query;

						this.$router.push({
							name: "leads-detail",
							params: this.leadId,
							query: {
								...filterQuery,
								t: new Date().getTime(),
							},
						});
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Lead updated successfully." },
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: `Success ! Lead ${this.leadsId > 0 ? "updated" : "added"} successfully.`,
							},
						]);

						let filterQuery = this.$route.query;
						let user_filter = 0;
						let objQuery = { t: new Date().getTime() };
						if (filterQuery.user_filter) {
							user_filter = filterQuery.user_filter;
							objQuery.user_filter = user_filter;
						}

						this.$router.push({
							name: "leads",
							query: objQuery,
						});
					}
					this.$emit("refresh", true);
					/* RemarkEventBus.$emit("table-refresh", true); */
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "lead-setting" })
				.then((data) => {
					let statusData = cloneDeep(data.status);
					statusData.shift(1);
					_this.statusList = statusData;
					_this.sourceList = data.source;
					_this.positonList = data.positon;
					_this.opportunityList = data.opportunity;
					_this.designationList = data.designation;
					_this.productList = data.product_type;
					_this.countryList = data.country;

					if (Array.isArray(data.users) && data.users.length) {
						_this.userList = data.users.filter((user) => user.users_type == "sales");
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getLead() {
			if (this.leadsId > 0) {
				this.leadId = this.leadsId;
			}
			this.$store
				.dispatch(GET, { url: `lead/${this.leadId}` })
				.then((data) => {
					this.create_lead.status = data.lead.status;
					this.create_lead.source = data.lead.source;
					this.create_lead.opportunity = data.lead.opportunity;
					this.create_lead.product_type = data.lead.product_type;
					this.create_lead.assign = data.lead.assign;
					this.create_lead.amount = Number(data.lead.amount).toFixed(2);
					this.create_lead.unit_number = data.lead.unit_number;
					this.create_lead.customer_name = data.lead.customer_name;
					this.create_lead.company_name = data.lead.company_name;
					this.create_lead.position = data.lead.position;
					this.create_lead.email = data.lead.email;
					this.create_lead.phone = data.lead.phone;
					this.create_lead.website = data.lead.website;
					this.create_lead.lead_date = data.lead.lead_date;
					this.create_lead.address_line_1 = data.lead.address_line_1;
					this.create_lead.address_line_2 = data.lead.address_line_2;
					this.create_lead.country = data.lead.country;
					this.create_lead.postal_code = data.lead.postal_code;
					this.create_lead.remark = data.lead.remark && data.lead.remark.replaceAll("\r", "");
					this.create_lead.email_reply =
						data.lead.email_reply && data.lead.email_reply.replaceAll("\r", "");
					this.create_lead.is_public = data.lead.is_public;
					this.create_lead.contacted_today = data.lead.contacted_today;
					/* this.create_lead.contact_person = data.contact; */
					this.create_lead.contact_person = map(data.contact, (row) => {
						return { ...row, primary: row.primary == 0 ? false : true };
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getDuplicateLead() {
			this.$store
				.dispatch(GET, { url: `lead/${this.leadDuplicate}` })
				.then((data) => {
					this.create_lead.status = data.lead.status;
					this.create_lead.source = data.lead.source;
					this.create_lead.opportunity = data.lead.opportunity;
					this.create_lead.product_type = data.lead.product_type;
					this.create_lead.assign = data.lead.assign;
					this.create_lead.amount = Number(data.lead.amount).toFixed(2);
					this.create_lead.unit_number = data.lead.unit_number;
					this.create_lead.customer_name = data.lead.customer_name;
					this.create_lead.company_name = data.lead.company_name;
					this.create_lead.position = data.lead.position;
					this.create_lead.email = data.lead.email;
					this.create_lead.phone = data.lead.phone;
					this.create_lead.website = data.lead.website;
					this.create_lead.lead_date = data.lead.lead_date;
					this.create_lead.address_line_1 = data.lead.address_line_1;
					this.create_lead.address_line_2 = data.lead.address_line_2;
					this.create_lead.country = data.lead.country;
					this.create_lead.postal_code = data.lead.postal_code;
					this.create_lead.remark = data.lead.remark && data.lead.remark.replaceAll("\r", "");
					this.create_lead.email_reply =
						data.lead.email_reply && data.lead.email_reply.replaceAll("\r", "");
					this.create_lead.is_public = data.lead.is_public;
					this.create_lead.contacted_today = data.lead.contacted_today;
					/* this.create_lead.contact_person = data.contact; */
					/* this.create_lead.contact_person = map(data.contact, (row) => {
						return { ...row, primary: row.primary == 0 ? false : true };
					}); */
					this.create_lead.contact_person = map(data.contact, (row) => {
						return { ...row, id: 0 };
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors", "currentUser"]),
		userId() {
			const user = this.currentUserData();
			const userId = user.id;
			return userId;
		},
	},
	created() {
		if (this.leadId) {
			this.getLead();
			this.pageTitle = "Update Lead";
		}
	},
	mounted() {
		if (!this.getPermission("lead:create") && this.leadId == 0) {
			this.$router.replace({
				name: "leads",
				query: { t: new Date().getTime() },
				date: [],
			});
		}
		if (!this.getPermission("lead:update") && this.leadId > 0) {
			this.$router.replace({
				name: "leads",
				query: { t: new Date().getTime() },
				date: [],
			});
		}
		if (this.leadId == 0) {
			this.create_lead.assign = this.userId;
		}
		if (this.leadsId > 0) {
			this.leadId = this.leadsId;
		}
		this.getSettings();
		if (this.leadId) {
			this.getLead();
			this.pageTitle = "Update Lead";
		}
		if (this.leadDuplicate) {
			this.getDuplicateLead();
			this.pageTitle = "Update Lead";
		}

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Lead", disabled: false, href: "/contacts/leads" },
			{ text: "Create", disabled: true },
		]);

		if (this.leadId) {
			this.$store.dispatch(SET_BREADCRUMB, [
				{ text: "Lead", disabled: false, href: "/contacts/leads" },
				{ text: "Update", disabled: true },
			]);
		}
	},
	beforeMount() {
		this.leadId = toSafeInteger(this.$route.params.id);
		this.leadDuplicate = toSafeInteger(this.$route.query.duplicate);
		this.currentPage = toSafeInteger(this.$route.query.page);
	},
};
</script>
